import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { Observable, of } from 'rxjs';
import { isNullEmpty } from '../shared.function';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private _router: Router, private _authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url;
    return this._check(redirectUrl);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url;
    return this._check(redirectUrl);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  private _check(redirectURL: string): Observable<boolean> {
    const token = this._authService.accessToken$;
    if (isNullEmpty(token)) {
      return of(false);
    }
    this._authService.tokenData$.subscribe((data) => {
      if (isNullEmpty(token) || (data.exp && data.exp * 1000 <= new Date().getTime())) {
        this._router.navigate(['sign-in'], {
          queryParams: { redirectURL },
        });
        return of(false);
      }
    });

    return of(true);
  }
}
