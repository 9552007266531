import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { User } from 'app/modules/admin/user/user.model';
import { AuthService, IJwtTokenData } from 'app/services/auth.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule],
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  user: User = new User();
  tokenData: IJwtTokenData;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _router: Router, private _authService: AuthService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._authService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      if (user) this.user = user;

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });

    this._authService.tokenData$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data: IJwtTokenData) => {
      if (data) this.tokenData = data;

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  //   updateUserStatus(status: string): void {
  //     if (!this.user) {
  //       return;
  //     }

  //     this._userService
  //       .update({
  //         ...this.user,
  //         status,
  //       })
  //       .subscribe();
  //   }

  profile(): void {
    this._router.navigate(['/profile']);
  }

  switchAccount(): void {
    this._router.navigate(['/accounts']);
  }

  signOut(): void {
    this._authService.signOut();
  }
}
