import * as _ from 'lodash';

export function getPropertyValue<T>(obj: T, property: string): null | string | number | boolean {
  let clonedObj = _.cloneDeep(obj) as any;
  const nestedProps = property.split('.');
  let value = null;
  nestedProps.forEach((prop) => {
    if (typeof clonedObj[prop] === 'object') {
      clonedObj = clonedObj[prop];
    } else {
      value = clonedObj[prop];
    }
  });

  return value;
}

export function loadScripts(scriptsArr: string[] = []) {
  const dynamicScripts = [...scriptsArr];

  dynamicScripts.forEach((script) => {
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.src = script;
    node.async = false;
    document.getElementsByTagName('head')[0].appendChild(node);
  });
}

export function isNullEmpty(val: any): boolean {
  if (val == undefined || val == null || val.trim() == '' || val.toLowerCase() == 'nan') return true;
  else return false;
}

export function enumToArray(item: any) {
  let resArr: { key: string; value: number }[] = [];
  Object.values(item).forEach((value) => {
    if (isNaN(Number(value)) === false) {
      const val = Number(value);
      resArr.push({
        key: item[val],
        value: val,
      });
    }
  });
  return resArr;
}
