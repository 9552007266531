export enum ActionEnum {
  Add = 1,
  View,
  Edit,
  Delete,
}

export enum PermissionEnum {
  Create = 1,
  CreateSelf,
  Read,
  ReadSelf,
  Update,
  UpdateSelf,
  Delete,
  DeleteSelf,
}

export enum Role {
  SuperAdmin = 1,
  Admin,
  Hospital,
  Corporate,
  ServiceProvider,
  Employee,
  Individual,
}

export enum Gender {
  Male = 1,
  Female,
  Transgender,
}

export enum LoginType {
  TwoFactorAuthentication = 1,
  Password,
  Otp,
}

export enum DeviceType {
  Web = 1,
  Android,
  iOS,
}

export enum AuthType {
  Email = 1,
  Mobile,
}

export enum MaritalStatusEnum {
  Single = 1,
  Engaged,
  Married,
  Defacto,
  Divorced,
  Separated,
  Widowed,
}

export enum ModuleEnum {
  // Auth
  Tenant = 1,
  Corporate,
  User = 100,
  Auth,
  // Master
  Country = 200,
  State,
  City,
  Goal,
  ServiceCategory,
  SpecializedCategory,
  ProgramCategory,
  Program,
  HealthIssueCategory,
  HealthIssue,
  Package,
  CustomizedPackage,
  // Core
  Appointment = 300,
  Transaction,
}

export enum DayOfWeekEnum {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum PackageType {
  Package = 'PACKAGE',
  Session = 'SESSION',
}

export enum PackageOwnerType {
  Individual = 'INDIVIDUAL',
  Corporate = 'CORPORATE',
}

export enum PackageStatus {
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY-PAID',
  Cancelled = 'CANCELLED',
}

export enum PaymentStatus {
  Initiated = 'INITIATED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
}

export enum TransactionType {
  Package = 'PACKAGE',
  Session = 'SESSION',
}

export enum PaymentMode {
  Cash = 'CASH',
  Online = 'ONLINE',
  Cheque = 'CHEQUE',
  BankTransfer = 'BANK_TRANSFER',
}

export enum AppointmentStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export enum AppointmentRecordType {
  SpecializedProgram = 'SPECIALIZED-PROGRAM',
  Program = 'PROGRAM',
}

export enum AppointmentBookingType {
  New = 'NEW',
  Repeat = 'REPEAT',
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
}
