import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CountryResModel } from 'app/dtos/country/country.model';
import { OrganizationalAssessmentResModel } from 'app/dtos/organizational-assessment/organizational-assessment.model';
import { CountryService } from 'app/services/country.service';
import { OrganizationalAssessmentService } from 'app/services/organizational-assessment.service';
import { focusInvalidInput } from 'app/shared/custom.validators';
import { NotificationService } from 'app/shared/services/notification.service';
import _ from 'lodash';
import { finalize } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

interface IQuestion {
  formControlName: string;
  question: string;
  type: string;
  options?: {
    label?: string;
    value: string;
  }[];
  min?: number;
  max?: number;
  maxLength?: number;
  dependentFormGroup?: string;
  dependentOn?: string;
  dependentOnValueEqualTo?: string;
  dependentOnValueNotEqualTo?: string;
}
interface IQuestionSection {
  formGroupName: string;
  section: string;
  sectionHeading: string;
  questions: IQuestion[];
}

@Component({
  selector: 'organizational-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
  ],
  providers: [CountryService, AuthService, OrganizationalAssessmentService],
})
export class OrganizationalAssessmentCommonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isReadOnly: boolean = false;
  @Input() email: string;
  @Output() isFormSubmitted = new EventEmitter<boolean>();
  @Output() totalQuestions = new EventEmitter<number>();
  @Output() completionPercentage = new EventEmitter<number>();
  @Output() questions = new EventEmitter<IQuestionSection[]>();

  form: FormGroup;
  isSubmitted: boolean;
  roles: { label: string; value: string }[] = [
    { label: 'Co-Founder/Owner', value: 'Owner' },
    { label: 'Managing Director', value: 'ManagingDirector' },
    { label: 'Strategy', value: 'Strategy' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Sales and marketing', value: 'SalesAndMarketing' },
    { label: 'Operations', value: 'Operations' },
    { label: 'Business development', value: 'BusinessDevelopment' },
    { label: 'Human Resource', value: 'HumanResource' },
    { label: 'Research & Development', value: 'ResearchDevelopment' },
    { label: 'Information Technology', value: 'InformationTechnology' },
    { label: 'Legal', value: 'Legal' },
    { label: 'Customer Service', value: 'CustomerService' },
    { label: 'Customer Relationship', value: 'CustomerRelationship' },
    { label: 'Governance', value: 'Governance' },
    { label: 'Distribution', value: 'Distribution' },
    { label: 'Design', value: 'Design' },
    { label: 'Communication', value: 'Communication' },
    { label: 'Production', value: 'Production' },
    { label: 'Sourcing', value: 'Sourcing' },
    { label: 'Quality Management', value: 'QualityManagement' },
  ];
  countries: CountryResModel[] = [];
  questionsArr: IQuestionSection[] = [
    {
      formGroupName: 'organizationDetails',
      section: 'Organization Details',
      sectionHeading: 'Organization Details',
      questions: [
        {
          formControlName: 'organizationName',
          type: 'text',
          question: 'Organization name',
        },
        {
          formControlName: 'organizationEmail',
          type: 'email',
          question: 'Email id of your organization',
        },
        {
          formControlName: 'organizationAge',
          type: 'radio',
          question: 'How old is your organization ?',
          options: [
            { value: '<1 Years old' },
            { value: '> 1 Years and <= 3 Years' },
            { value: '> 3 Years and <= 10 Years' },
            { value: '> 10 Years' },
          ],
        },
        {
          formControlName: 'organizationEmployeeStrength',
          type: 'radio',
          question: 'Approx Employee strength of your Organization',
          options: [{ value: '< 50' }, { value: '> 50  and <= 100' }, { value: '> 100 and <= 500' }, { value: '> 500' }],
        },
        {
          formControlName: 'yourRole',
          type: 'select',
          question: 'Please select your Role',
          options: this.roles,
        },
        {
          formControlName: 'yourCountry',
          type: 'select',
          question: 'Your Country',
          options: _.map(this.countries, (country) => {
            return { label: country.name, value: country.shortName };
          }),
        },
        {
          formControlName: 'yourCity',
          type: 'text',
          question: 'Your City',
        },
        {
          formControlName: 'organizationOfficeAddress',
          type: 'text',
          question: 'Your organization office address',
        },
      ],
    },
    {
      formGroupName: 'eap',
      section: 'Organization Culture, Employee Assistance Program (EAP), Employee Retention & Attrition',
      sectionHeading: 'EAP',
      questions: [
        {
          formControlName: 'describePrevailingCommunicationStyleWithinOrganization',
          type: 'radio',
          question: 'How would you describe the prevailing communication style within the organization?',
          options: [
            { value: 'Open and Transparent' },
            { value: 'Formal and Structured' },
            { value: 'Hierarchical and Controlled' },
            { value: 'Informal and Collaborative' },
          ],
        },
        {
          formControlName: 'whatExtentYourPpinionsIdeasAreValued',
          type: 'radio',
          question: 'To what extent do you feel your opinions and ideas are valued and considered in decision-making processes?',
          options: [
            { value: 'Fully Valued' },
            { value: 'Somewhat Valued' },
            { value: 'Neutral' },
            { value: 'Not Very Valued' },
            { value: 'Not Valued at All' },
          ],
        },
        {
          formControlName: 'howOftenDoYouWitnessBehaviorsThatPromoteDiversity',
          type: 'radio',
          question: 'How often do you witness or experience behaviors that promote diversity and inclusion in the workplace?',
          options: [{ value: 'Very Often' }, { value: 'Often' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'Never' }],
        },
        {
          formControlName: 'rateLevelOfTrustMutualRespectAmongColleagues',
          type: 'radio',
          question: 'How would you rate the overall level of trust and mutual respect among colleagues and management?',
          options: [{ value: 'Very High' }, { value: 'High' }, { value: 'Moderate' }, { value: 'Low' }, { value: 'Very Low' }],
        },
        {
          formControlName: 'doesOrganizationCultureAlignWithStatedValues',
          type: 'radio',
          question: "In your opinion, does the organization's culture align with its stated values and mission?",
          options: [
            { value: 'Completely Aligned' },
            { value: 'Somewhat Aligned' },
            { value: 'Neutral' },
            { value: 'Partially Aligned' },
            { value: 'Not Aligned at All' },
          ],
        },
        {
          formControlName: 'doesYourOrganizationOfferEmployeeAssistanceProgram',
          type: 'radio',
          question: 'Does your organization offer Employee Assistance Program (EAP) ?',
          options: [{ value: 'Yes' }, { value: 'No' }, { value: 'whenever needed' }],
        },
        {
          formControlName: 'haveYouEverUsedEAPServices',
          type: 'radio',
          dependentOn: 'doesYourOrganizationOfferEmployeeAssistanceProgram',
          dependentOnValueNotEqualTo: 'No',
          question: 'Have you ever used the EAP services to address personal or work-related challenges?',
          options: [
            { value: 'Yes, and it was helpful' },
            { value: 'Yes, but it was not very helpful' },
            { value: 'No, I have not used EAP services' },
          ],
        },
        {
          formControlName: 'howComfortableWithIdeaOfSeekingAssistanceFromEAP',
          type: 'radio',
          dependentOn: 'doesYourOrganizationOfferEmployeeAssistanceProgram',
          dependentOnValueNotEqualTo: 'No',
          question: 'How comfortable are you with the idea of seeking assistance from the EAP for your well-being?',
          options: [
            { value: 'Very Comfortable' },
            { value: 'Comfortable' },
            { value: 'Neutral' },
            { value: 'Uncomfortable' },
            { value: 'Very Low' },
          ],
        },
        {
          formControlName: 'howSatisfiedWithOrganizationRewardsRecognitionPrograms',
          type: 'radio',
          question: "How satisfied are you with the organization's rewards and recognition programs?",
          options: [
            { value: 'Very Satisfied' },
            { value: 'Satisfied' },
            { value: 'Neutral' },
            { value: 'Dissatisfied' },
            { value: 'Very Dissatisfied' },
          ],
        },
        {
          formControlName: 'employeesWorkingFromMoreThan5Years',
          type: 'radio',
          question: 'How many employees (in percentage) are working from more than 5 years ?',
          options: [
            { value: '<10%' },
            { value: '> 10% to <= 20%' },
            { value: '> 20% to <= 35%' },
            { value: '> 35% to <=50%' },
            { value: '> 50%' },
          ],
          dependentFormGroup: 'organizationDetails',
          dependentOn: 'yourRole',
          dependentOnValueEqualTo: 'HumanResource',
        },
        {
          formControlName: 'averageAttritionRateOfYourOrganization',
          type: 'number',
          question: 'What is the average attrition rate of your organization? (Please specify in percentage like 5, 10, 60)',
          dependentFormGroup: 'organizationDetails',
          dependentOn: 'yourRole',
          dependentOnValueEqualTo: 'HumanResource',
        },
        {
          formControlName: 'anySpecificFactorsThatContributeToHighAbsenteeism',
          type: 'text',
          question:
            'Are you aware of any specific factors that contribute to high absenteeism and sick leaves among employees? Please Specify',
        },
        {
          formControlName: 'anyDepartmentsOrJobRolesExperiencingHigherAbsenteeism',
          type: 'text',
          question:
            'Have you observed any trends in terms of departments or job roles experiencing higher absenteeism and sick leaves? Please specify',
        },
      ],
    },
    {
      formGroupName: 'socialConnectionAndCommunity',
      section: 'Social Connection & Community, Work Environment & Work Life Balance',
      sectionHeading: 'Social Connection',
      questions: [
        {
          formControlName: 'areThereEmployeeResourceGorups',
          type: 'radio',
          question: 'Are there employee resource groups or affinity networks?',
          options: [{ value: 'Multiple groups' }, { value: 'A few groups' }, { value: 'None' }, { value: 'Unsure' }],
        },
        {
          formControlName: 'doesOrganizationOrganizeTeamBuildingActivity',
          type: 'radio',
          question: 'Does the organization organize team-building activities?',
          options: [{ value: 'Often' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'Never' }],
        },
        {
          formControlName: 'areThereOpportunitiesForEmployeesToEngageInVolunteering',
          type: 'radio',
          question: 'How frequently opportunities  are given for employees to engage in volunteering or community service?',
          options: [{ value: 'Regularly' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'Never' }],
          dependentOn: 'doesOrganizationOrganizeTeamBuildingActivity',
          dependentOnValueNotEqualTo: 'Never',
        },
        {
          formControlName: 'rateLevelOfCollaborationCrossFunctionalEngagement',
          type: 'radio',
          question: 'How would you rate the level of collaboration and cross-functional engagement among employees?',
          options: [{ value: 'High' }, { value: 'Moderate' }, { value: 'Low' }, { value: 'Unsure' }],
        },
        {
          formControlName: 'doEmployeesHaveOptionToWorkRemotely',
          type: 'radio',
          question: 'Do employees have the option to work remotely?',
          options: [{ value: 'Yes, Always' }, { value: 'Yes, Occasionally' }, { value: 'Rarely' }, { value: 'No' }],
        },
        {
          formControlName: 'howOftenWorkingOutsideOfRegularOfficeHours',
          type: 'radio',
          question: 'How frequently do you find yourself working outside of regular office hours to meet work demands?',
          options: [
            { value: 'Rarely or Never' },
            { value: 'Occasionally' },
            { value: 'Sometimes' },
            { value: 'Often' },
            { value: 'Always' },
          ],
        },
        {
          formControlName: 'howOftenWorkResponsibilitiesInterfereYourPersonalTime',
          type: 'radio',
          question: 'How often do you feel that work-related responsibilities interfere with your personal or family time?',
          options: [{ value: 'Very Often' }, { value: 'Often' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'Never' }],
        },
        {
          formControlName: 'areYouSatisfiedWithOrganizationPolicies',
          type: 'radio',
          question: "Are you satisfied with the organization's policies and initiatives aimed at promoting work-life balance?",
          options: [
            { value: 'Very Satisfied' },
            { value: 'Satisfied' },
            { value: 'Neutral' },
            { value: 'Dissatisfied' },
            { value: 'Very Dissatisfied' },
          ],
        },
        {
          formControlName: 'howWellOrganizationSupportEmployeesFamilyNeeds',
          type: 'radio',
          question: "How well does the organization accommodate and support employees' family needs or personal commitments?",
          options: [
            { value: 'Very Well' },
            { value: 'Moderately Well' },
            { value: 'Neutral' },
            { value: 'Not Very Well' },
            { value: 'Poorly' },
          ],
        },
        {
          formControlName: 'isOpenCommunicationCultureEncouragesFeedback',
          type: 'radio',
          question: 'Is there an open communication culture that encourages feedback and concerns?',
          options: [
            { label: 'Yes, Always', value: 'Always' },
            { label: 'Yes, Often', value: 'Often' },
            { label: 'Yes, to some extent', value: 'to some extent' },
            { label: 'Never', value: 'Never' },
          ],
        },
        {
          formControlName: 'opportunitiesForSkillDevelopmentProvided',
          type: 'radio',
          question: 'Are opportunities for skill development and growth provided to employees?',
          options: [
            { label: 'Yes, Extensively', value: 'Extensively' },
            { label: 'Yes, Occasionally', value: 'Occasionally' },
            { label: 'Yes, Rarely', value: 'Rarely' },
            { label: 'Not at all', value: 'Not at all' },
          ],
        },
        {
          formControlName: 'areEmployeesRecognizedForContributions',
          type: 'radio',
          question: 'Are employees recognized for their contributions and achievements?',
          options: [{ value: 'Regularly' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'Never' }],
        },
        {
          formControlName: 'describeLevelOfCollaborationAcrossDepartments',
          type: 'radio',
          question: 'How would you describe the level of collaboration and teamwork across departments or teams?',
          options: [{ value: 'Very High' }, { value: 'High' }, { value: 'Moderate' }, { value: 'Low' }, { value: 'Very Low' }],
        },
        {
          formControlName: 'howComfortableAreYouDiscussingWorkRelatedConcernsWithImmediateSupervisor',
          type: 'radio',
          question: 'How comfortable are you discussing work-related concerns or challenges with your immediate supervisor?',
          options: [
            { value: 'Very Comfortable' },
            { value: 'Comfortable' },
            { value: 'Neutral' },
            { value: 'Uncomfortable' },
            { value: 'Very Uncomfortable' },
          ],
        },
        {
          formControlName: 'anythingYouLikeToShareAboutOrganizationCulture',
          type: 'text',
          question:
            "Is there anything else you would like to share about the organization's culture, workplace environment, or work-life balance? Please Specify !",
        },
      ],
    },
    {
      formGroupName: 'generalWellbeing',
      section: 'General Wellbeing, Leadership and Support',
      sectionHeading: 'General Wellbeing',
      questions: [
        {
          formControlName: 'howWellDoesTheOrganizationPromoteSupportivePositiveAtmosphere',
          type: 'radio',
          question: "How well does the organization promote a supportive and positive atmosphere for employees' well-being?",
          options: [
            { value: 'Very Well' },
            { value: 'Moderately Well' },
            { value: 'Neutral' },
            { value: 'Not Very Well' },
            { value: 'Poorly' },
          ],
        },
        {
          formControlName: 'howSatisfiedEmployeesWithOrganizationApproachToWellbeing',
          type: 'radio',
          question: "Overall, how satisfied are employees with the organization's approach to health and wellbeing?",
          options: [
            { value: 'Very satisfied' },
            { value: 'Satisfied' },
            { value: 'Neutral' },
            { value: 'Unsatisfied' },
            { value: 'Very unsatisfied' },
          ],
        },
        {
          formControlName: 'howOftenAreWellbeingInitiativesCommunicatedToEmployees',
          type: 'radio',
          question: 'How often are wellbeing initiatives communicated to employees?',
          options: [{ value: 'Regularly' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'Never' }],
        },
        {
          formControlName: 'doesOrganizationProvideIncentivesOrRewardsForMaintainingGoodHealth',
          type: 'radio',
          question:
            'Does your organization provide incentives or rewards for maintaining good health and participating in wellness activities?',
          options: [{ value: 'Yes, Always' }, { value: 'Yes, Occasionally' }, { value: 'Rarely' }, { value: 'No' }],
        },
        {
          formControlName: 'areThereMechanismsToAddressFairPayAndEquitableCompensation',
          type: 'radio',
          question: 'Are there mechanisms to address concerns about fair pay and equitable compensation?',
          options: [
            { value: 'Strong mechanisms in place' },
            { value: 'Some mechanisms' },
            { value: 'Minimal mechanisms' },
            { value: 'No mechanisms' },
          ],
        },
        {
          formControlName: 'howWellOrganizationSupportWorkLifeBalance',
          type: 'radio',
          question: "How well does the organization support employees' work-life balance?",
          options: [{ value: 'Excellent support' }, { value: 'Good support' }, { value: 'Fair support' }, { value: 'Poor support' }],
        },
        {
          formControlName: 'HowVisibleAreLeadersInPromotingHealthWellbeing',
          type: 'radio',
          question: 'How visible and accessible are senior leaders in promoting health and wellbeing?',
          options: [
            { value: 'Highly visible and accessible' },
            { value: 'Moderately visible and accessible' },
            { value: 'Slightly visible and accessible' },
            { value: 'Not visible or accessible' },
          ],
        },
        {
          formControlName: 'doManagersReceiveTrainingOnSupportingEmployeeWellbeing',
          type: 'radio',
          question: 'Do managers receive training on supporting employee wellbeing?',
          options: [
            { value: 'Yes, extensive training' },
            { value: 'Some training' },
            { value: 'Minimal training' },
            { value: 'No training' },
          ],
        },
        {
          formControlName: 'areThereDesignatedWellnessChampionsWithinOrganization',
          type: 'radio',
          question: 'Are there designated wellness champions within the organization?',
          options: [
            { value: 'Yes, multiple champions' },
            { value: 'A few champions' },
            { value: 'No designated champions' },
            { value: 'Unsure' },
          ],
        },
        {
          formControlName: 'howAlignedWellbeingInitiativesWithTheCompanyMission',
          type: 'radio',
          question: "How aligned are wellbeing initiatives with the company's mission and values?",
          options: [{ value: 'Highly aligned' }, { value: 'Somewhat aligned' }, { value: 'Slightly aligned' }, { value: 'Not aligned' }],
        },
      ],
    },
    {
      formGroupName: 'mentalHealthAssessment',
      section: 'Mental and Emotional Wellbeing',
      sectionHeading: 'Emotional Wellbeing',
      questions: [
        {
          formControlName: 'areEmployeesEncouragedToTakeMentalHealthDays',
          type: 'radio',
          question: 'Are employees encouraged to take mental health days when needed?',
          options: [{ value: 'Always' }, { value: 'Often' }, { value: 'Occasionally' }, { value: 'Rarely' }],
        },
        {
          formControlName: 'doesOrganizationProvideResourcesForStressManagement',
          type: 'radio',
          question: 'Does the organization provide resources for stress management and resilience training?',
          options: [{ value: 'Yes, regularly' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'No' }],
        },
        {
          formControlName: 'areManagersTrainedToRecognizeSignsOfEmotionalDistress',
          type: 'radio',
          question: 'Are managers trained to recognize signs of emotional distress and provide support?',
          options: [
            { value: 'Yes, extensive training' },
            { value: 'Some training' },
            { value: 'Minimal training' },
            { value: 'No training' },
          ],
        },
        {
          formControlName: 'areMindfulnessAndMeditationSessionsOfferedToEmployees',
          type: 'radio',
          question: 'Are mindfulness and meditation sessions offered to employees?',
          options: [{ value: 'Regularly' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'Never' }],
        },
        {
          formControlName: 'doesOrganizationOfferProfessionalCounsellingForMentalHealthConcernsRelatedToWork',
          type: 'radio',
          question:
            'Does organization offer professional help or counselling for mental health concerns related to work for their employees?',
          options: [{ value: 'Yes' }, { value: 'No' }, { value: 'Yes when needed' }],
        },
      ],
    },
    {
      formGroupName: 'healthRiskAssessment',
      section: 'Health Risk Assessment',
      sectionHeading: 'Health Assessment',
      questions: [
        {
          formControlName: 'rateTheAvailabilityOfFitnessFacilitiesForPhysicalActivityWithinWorkplace',
          type: 'radio',
          question:
            'How would you rate the availability of fitness facilities or opportunities for physical activity within the workplace?',
          options: [{ value: 'Excellent' }, { value: 'Good' }, { value: 'Fair' }, { value: 'Poor' }],
        },
        {
          formControlName: 'doesOrganizationOfferHealthScreeningsAndWellnessCheckupsToEmployee',
          type: 'radio',
          question: 'Does the organization offer health screenings and wellness check-ups to employees on a regular basis?',
          options: [{ value: 'Yes, regularly' }, { value: 'Occasionally' }, { value: 'Rarely' }, { value: 'No' }],
        },
        {
          formControlName: 'ergonomicWorkstationsProvidedToPromotePhysicalComfort',
          type: 'radio',
          question: 'Are ergonomic workstations and equipment provided to promote physical comfort?',
          options: [{ value: 'Always' }, { value: 'Often' }, { value: 'Sometimes' }, { value: 'Rarely' }],
        },
        {
          formControlName: 'doesWorkplaceCafeteriaOfferHealthyFoodOptions',
          type: 'radio',
          question: 'Does the workplace cafeteria offer healthy food options?',
          options: [{ value: 'Yes, a wide variety' }, { value: 'Yes, limited options' }, { value: 'No' }, { value: 'N/A' }],
        },
        {
          formControlName: 'averageSickLeavesTakesByEmployeeEveryMonth',
          type: 'number',
          min: 0,
          max: 30,
          question: 'Average sick leaves usually takes by the employee every month?',
        },
        {
          formControlName: 'averageMonthlyEmployeeAbsentismDueToSickLeave',
          type: 'number',
          min: 0,
          max: 30,
          question: 'Average monthly employee absentism due to sick leave?',
        },
        {
          formControlName: 'doesOrganizationOfferHealthInsuranceBenefits',
          type: 'radio',
          question: 'Does your organization offer Health Insurance benefits?',
          options: [{ value: 'Yes' }, { value: 'No' }],
        },
        {
          formControlName: 'employeesAvailedHealthInsuranceBenefits',
          type: 'text',
          question: 'How many employees availed health insurance benefits in the past year?',
        },
      ],
    },
  ];
  assessment: OrganizationalAssessmentResModel = new OrganizationalAssessmentResModel();
  totalQuestionsCount = 0;
  token: string;

  constructor(
    private _assessmentService: OrganizationalAssessmentService,
    private _countryService: CountryService,
    private _notificationService: NotificationService,
    private _el: ElementRef,
  ) {
    this.form = new FormGroup({});

    this.questionsArr.forEach((sec) => {
      let questionsGroup = new FormGroup({});
      sec.questions.forEach((ques) => {
        questionsGroup.addControl(ques.formControlName, new FormControl(null, this.computeValidators(ques)));
      });
      this.form.addControl(sec.formGroupName, questionsGroup);
      this.totalQuestionsCount += sec.questions.length;
    });

    this.questions.emit(this.questionsArr);
    this.totalQuestions.emit(this.totalQuestionsCount);
  }

  ngOnInit() {
    // document.getElementsByTagName('body')[0].style.minWidth = '1200px';

    this.form.valueChanges.subscribe(() => {
      this.calculateCompletionPercentage();
    });
    this.prepareDropDown();
  }

  ngOnDestroy(): void {
    // document.getElementsByTagName('body')[0].style.removeProperty('min-width');
  }

  ngOnChanges(): void {
    if (this.email) this.getAssessmentByEmail();

    if (this.isReadOnly) this.form.disable();
  }

  validateForm(form: FormGroup): boolean {
    form.markAllAsTouched();
    this.isSubmitted = true;
    if (form.invalid) {
      focusInvalidInput(this._el);
    }
    return form.valid;
  }

  getSubFormGroup(formGroupName: string, form: FormGroup = this.form): FormGroup {
    return form.get(formGroupName) as FormGroup;
  }

  prepareDropDown(): void {
    this.fetchCountries();
  }

  fetchCountries(): void {
    let param = new HttpParams()
      .append('PageOffset', 0)
      .append('PageSize', 10000)
      .append('OrderBy', 'name')
      .append('OrderDirection', 'ASC');
    this._countryService.GetAll(param).subscribe((apiRes) => {
      this.countries = apiRes.data.list;

      var section = _.find(this.questionsArr, { formGroupName: 'organizationDetails' });
      var elem = _.find(section.questions, { formControlName: 'yourCountry' });
      if (elem) {
        elem.options = _.map(this.countries, (country) => {
          return { label: country.name, value: country.shortName };
        });
      }
    });
  }

  getAssessment(id: string): void {
    this._assessmentService.Get(id, this.token).subscribe((apiRes) => {
      this.assessment = apiRes.data;
      this.form.patchValue(apiRes.data.assessment);
    });
  }

  getAssessmentByEmail(): void {
    this._assessmentService.GetByEmail(this.email, this.token).subscribe((apiRes) => {
      this.assessment = apiRes.data;
      this.form.patchValue(apiRes.data.assessment);
    });
  }

  submit(): void {
    if (!this.validateForm(this.form)) return;

    if (this.assessment?.id) {
      this.update(true);
    } else {
      this.create(true);
    }
  }

  create(isSubmitted: boolean): void {
    this.form.disable();
    this._assessmentService
      .Create({ email: this.email, assessment: this.form.getRawValue(), isSubmitted: isSubmitted }, this.token)
      .pipe(
        finalize(() => {
          this.form.enable();
        }),
      )
      .subscribe((apiRes) => {
        this._notificationService.notify(apiRes.message);
        this.getAssessment(apiRes.data);
      });
  }

  update(isSubmitted: boolean): void {
    this._assessmentService
      .Update(this.assessment.id, { email: this.email, assessment: this.form.getRawValue(), isSubmitted: isSubmitted }, this.token)
      .pipe(
        finalize(() => {
          this.form.enable();
        }),
      )
      .subscribe((apiRes) => {
        this._notificationService.notify(apiRes.message);
        if (isSubmitted) this.isFormSubmitted.emit(true);
      });
  }

  partialSave(form: string) {
    if (this.getSubFormGroup(form, this.form).valid) {
      if (this.assessment.id) {
        this.update(false);
      } else {
        this.create(false);
      }
    }
  }

  calculateCompletionPercentage(): void {
    let controls = this.controlsRecursive(this.form);
    let validControlsCount = controls?.validControls?.length ?? 0;
    let invalidControlsCount = controls?.invalidControls?.length ?? 0;
    let requiredControlsCount = controls?.requiredControls?.length ?? 0;

    this.totalQuestionsCount = requiredControlsCount;
    let completionPercentage = ((this.totalQuestionsCount - invalidControlsCount) * 100) / this.totalQuestionsCount;

    this.totalQuestions.emit(this.totalQuestionsCount);
    this.completionPercentage.emit(completionPercentage);
  }

  controlsRecursive(formToInvestigate: FormGroup | FormArray): {
    validControls: string[];
    invalidControls: string[];
    requiredControls: string[];
  } {
    var validControls: string[] = [];
    var invalidControls: string[] = [];
    var requiredControls: string[] = [];
    let recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        } else {
          if (control.invalid) invalidControls.push(field);
          else validControls.push(field);

          if (control.hasValidator(Validators.required)) {
            requiredControls.push(field);
          }
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return { validControls: validControls, invalidControls: invalidControls, requiredControls: requiredControls };
  }

  canShowQuestion(formGroupName: string, ques: IQuestion): boolean {
    if (ques.dependentOn) {
      let dependentForm = this.getSubFormGroup(ques.dependentFormGroup ?? formGroupName, this.form);
      let dependentPropertyValue = dependentForm.value[ques.dependentOn];
      if (ques.dependentOnValueEqualTo != null) {
        return dependentPropertyValue == ques.dependentOnValueEqualTo;
      } else if (ques.dependentOnValueNotEqualTo != null) {
        return dependentPropertyValue != ques.dependentOnValueNotEqualTo;
      } else {
        return false;
      }
    }

    return true;
  }

  filterQuestions(sec: IQuestionSection): IQuestion[] {
    let filteredQuestions: IQuestion[] = [];

    sec.questions.forEach((ques) => {
      if (this.canShowQuestion(sec.formGroupName, ques)) {
        this.getSubFormGroup(sec.formGroupName, this.form).get(ques.formControlName).setValidators(this.computeValidators(ques));
        filteredQuestions.push(ques);
      } else {
        this.getSubFormGroup(sec.formGroupName, this.form).get(ques.formControlName).clearValidators();
        this.getSubFormGroup(sec.formGroupName, this.form).get(ques.formControlName).updateValueAndValidity();
      }
    });

    return filteredQuestions;
  }

  computeValidators(ques: IQuestion): ValidatorFn[] {
    return [
      Validators.required,
      ques.type == 'email' ? Validators.email : Validators.nullValidator,
      ques.min != null ? Validators.min(ques.min) : Validators.nullValidator,
      ques.max != null ? Validators.max(ques.max) : Validators.nullValidator,
      ques.maxLength != null ? Validators.maxLength(ques.maxLength) : Validators.nullValidator,
    ];
  }
}
