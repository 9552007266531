import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganizationalAssessmentResModel } from 'app/dtos/organizational-assessment/organizational-assessment.model';
import { IApiListResponse, IApiResponse } from 'app/shared/shared-interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationalAssessmentService {
  constructor(private _httpClient: HttpClient) {}

  Create(data: any, token?: string): Observable<IApiResponse<string>> {
    let header = new HttpHeaders();
    if (token) header = new HttpHeaders().set('Authorization', token);
    return this._httpClient.post<IApiResponse<null>>(`${environment.apiUrl}/g-core/v1.0/organizational-assessment`, data, {
      headers: header,
    });
  }

  GetAll(param: HttpParams): Observable<IApiListResponse<OrganizationalAssessmentResModel>> {
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/g-core/v1.0/organizational-assessment`, { params: param });
  }

  Get(id: string, token?: string): Observable<IApiResponse<OrganizationalAssessmentResModel>> {
    let header = new HttpHeaders();
    if (token) header = new HttpHeaders().set('Authorization', token);
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/g-core/v1.0/organizational-assessment/${id}`, {
      headers: header,
    });
  }

  GetByEmail(email: string, token?: string): Observable<IApiResponse<OrganizationalAssessmentResModel>> {
    let header = new HttpHeaders();
    if (token) header = new HttpHeaders().set('Authorization', token);
    return this._httpClient.get<IApiResponse<null>>(`${environment.apiUrl}/g-core/v1.0/organizational-assessment/email/${email}`, {
      headers: header,
    });
  }

  Update(id: string, data: any, token?: string): Observable<IApiResponse<OrganizationalAssessmentResModel>> {
    let header = new HttpHeaders();
    if (token) header = new HttpHeaders().set('Authorization', token);
    return this._httpClient.put<IApiResponse<null>>(`${environment.apiUrl}/g-core/v1.0/organizational-assessment/${id}`, data, {
      headers: header,
    });
  }

  Delete(id: string): Observable<IApiResponse<OrganizationalAssessmentResModel>> {
    return this._httpClient.delete<IApiResponse<null>>(`${environment.apiUrl}/g-core/v1.0/organizational-assessment/${id}`);
  }
}
