<div class="w-full md:my-10 md:mx-20 p-5 bg-card" *ngIf="part == 1 || part == 2 || part == 3">
  <section class="px-3">
    <div class="text-center text mb-5 pb-5 border-b relative">
      <img class="mb-3 mx-auto w-50" src="assets/images/logo/logo.png" />
      <h1 class="text-4xl">Organizational Wellbeing Assessment</h1>
      <div class="progress-spinner" *ngIf="part == 3">
        <mat-progress-spinner style="position: absolute" color="accent" mode="determinate" diameter="80" [value]="100">
        </mat-progress-spinner>
        <mat-progress-spinner color="primary" mode="determinate" diameter="80" [value]="completionPercentage"> </mat-progress-spinner>
        <div class="progress-spinner-content">
          <span style="font-size: 20px"> {{ completionPercentage | number : '1.0-1' }} % </span>
        </div>
      </div>
    </div>

    <ng-container>
      <div class="mt-10" *ngIf="part == 1">
        <div class="table-container theme-card">
          <table class="w-full responsive-grid-table">
            <thead>
              <tr class="bg-gray-50 sticky top-0">
                <th class="text-md font-medium text-gray-900 px-6 py-4 text-center">#</th>
                <th class="text-md font-medium text-gray-900 px-6 py-4 text-left">Assessment Category</th>
                <th class="text-md font-medium text-gray-900 px-6 py-4 text-center">Total Questions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                *ngFor="let item of questions; let i = index"
              >
                <td class="text-md text-gray-900 font-light px-6 py-4 text-center">{{ i + 1 }}</td>
                <td class="text-md text-gray-900 font-light px-6 py-4">{{ item.section }}</td>
                <td class="text-md text-gray-900 font-light px-6 py-4 text-center">{{ item.questions.length }}</td>
              </tr>
            </tbody>
            <tfoot>
              <td class="text-md font-medium text-gray-900 px-6 py-4"></td>
              <td class="text-md font-medium text-gray-900 px-6 py-4">Total</td>
              <td class="text-md font-medium text-gray-900 px-6 py-4 text-center">{{ totalQuestions }}</td>
            </tfoot>
          </table>
        </div>
        <div class="text-right">
          <button mat-raised-button color="primary" (click)="part = 2">Start Assessment</button>
        </div>
      </div>

      <div class="mt-10" *ngIf="part == 2">
        <form [formGroup]="form" class="mt-2">
          <div class="my-3 mb-10">
            <mat-form-field class="w-full">
              <mat-label>Please enter your email id</mat-label>
              <mat-hint>Assessment insights & Report with be shared with you over your email id</mat-hint>
              <input matInput type="email" formControlName="email" required />
            </mat-form-field>
          </div>

          <div class="my-3" *ngIf="isEmailOtpSent">
            <mat-form-field class="w-full">
              <mat-label>Enter OTP</mat-label>
              <input matInput type="text" formControlName="otp" required />
            </mat-form-field>
          </div>

          <re-captcha #sendOtpCaptchaRef="reCaptcha" (resolved)="sendOtpCaptchaResolved($event)" size="invisible" [hidden]="isMobileBuild">
          </re-captcha>
          <re-captcha
            #verifyOtpCaptchaRef="reCaptcha"
            (resolved)="verifyOtpCaptchaResolved($event)"
            size="invisible"
            [hidden]="isMobileBuild"
          >
          </re-captcha>

          <button
            mat-raised-button
            color="primary"
            (click)="isMobileBuild ? sendOtp() : verifySendOtpCaptcha(sendOtpCaptchaRef)"
            class="mt-4"
          >
            {{ isEmailOtpSent ? 'Resend' : 'Send' }} OTP
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="form.valid ? (isMobileBuild ? verifyOtp() : verifyVerifyOtpCaptcha(verifyOtpCaptchaRef)) : ''"
            class="ml-3 mt-4"
            [disabled]="!form.valid"
            *ngIf="isEmailOtpSent"
          >
            Verify OTP
          </button>
        </form>
      </div>

      <div class="mt-10" *ngIf="part == 3">
        <organizational-assessment
          [email]="form.getRawValue().email"
          (questions)="questions = $event"
          (isFormSubmitted)="formSubmitted($event)"
          (totalQuestions)="totalQuestions = $event"
          (completionPercentage)="completionPercentage = $event"
        ></organizational-assessment>
      </div>
    </ng-container>
  </section>
</div>
<div class="w-full md:my-10 md:mx-20 p-5 thankyou" *ngIf="part == 4">
  <div class="wrapper-1">
    <div class="wrapper-2">
      <h1>Thank you !</h1>
      <p>Thank You for completing the organisational Assessment.</p>
      <button class="go-home" routerLink="/sign-in">go home</button>
    </div>
    <div class="footer-like">
      <p>The Assessment Report shall be emailed to you within 48 working hours.</p>
    </div>
  </div>
</div>
