import { SpecializedCategoryResModel } from '../specialized-category/specialized-category.model';

export class SpecializedProgramSearchReqModel {
  constructor() {
    this.searchColumns = [];
    this.isActiveFilter = null;
    this.categoryFilter = null;
  }
  searchColumns: string[];
  isActiveFilter: string;
  categoryFilter: string;
}

export class SpecializedProgramResModel {
  createdBy: string;
  additionalInfo: string;
  benefits: string;
  price: number;
  categories: SpecializedProgramCategoryMappingResModel[];
  createdOn: string;
  description: string;
  id: number;
  imagePath: string;
  imageUrl: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedOn: string;
  title: string;
}

export class SpecializedProgramCategoryMappingResModel {
  programId: number;
  program: SpecializedProgramResModel;
  categoryId: number;
  category: SpecializedCategoryResModel;
}
