export class OrganizationalAssessmentResModel {
  id: string;
  email: string;
  assessment: OrganizationalAssessmentModel;
}

export interface OrganizationalAssessmentModel {
  organizationDetails: OrganizationDetailAssessmentModel;
  eap: OrganizationalEapAssessmentModel;
  socialConnectionAndCommunity: OrganizationalSocialConnectionAndCommunityAssessmentModel;
  generalWellbeing: OrganizationalGeneralWellbeingAssessmentModel;
  mentalHealthAssessment: OrganizationalMentalHealthAssessmentModel;
  healthRiskAssessment: OrganizationalHealthRiskAssessmentModel;
}

export interface OrganizationalEapAssessmentModel {
  describePrevailingCommunicationStyleWithinOrganization: any | null;
  whatExtentYourPpinionsIdeasAreValued: any | null;
  howOftenDoYouWitnessBehaviorsThatPromoteDiversity: any | null;
  rateLevelOfTrustMutualRespectAmongColleagues: any | null;
  doesOrganizationCultureAlignWithStatedValues: any | null;
  doesYourOrganizationOfferEmployeeAssistanceProgram: any | null;
  haveYouEverUsedEAPServices: any | null;
  howComfortableWithIdeaOfSeekingAssistanceFromEAP: any | null;
  howSatisfiedWithOrganizationRewardsRecognitionPrograms: any | null;
  employeesWorkingFromMoreThan5Years: any | null;
  averageAttritionRateOfYourOrganization: any | null;
  anySpecificFactorsThatContributeToHighAbsenteeism: any | null;
  anyDepartmentsOrJobRolesExperiencingHigherAbsenteeism: any | null;
}

export interface OrganizationalGeneralWellbeingAssessmentModel {
  howWellDoesTheOrganizationPromoteSupportivePositiveAtmosphere: any | null;
  howSatisfiedEmployeesWithOrganizationApproachToWellbeing: any | null;
  howOftenAreWellbeingInitiativesCommunicatedToEmployees: any | null;
  doesOrganizationProvideIncentivesOrRewardsForMaintainingGoodHealth: any | null;
  areThereMechanismsToAddressFairPayAndEquitableCompensation: any | null;
  howWellOrganizationSupportWorkLifeBalance: any | null;
  howVisibleAreLeadersInPromotingHealthWellbeing: any | null;
  doManagersReceiveTrainingOnSupportingEmployeeWellbeing: any | null;
  areThereDesignatedWellnessChampionsWithinOrganization: any | null;
  howAlignedWellbeingInitiativesWithTheCompanyMission: any | null;
}

export interface OrganizationalHealthRiskAssessmentModel {
  rateTheAvailabilityOfFitnessFacilitiesForPhysicalActivityWithinWorkplace: any | null;
  doesOrganizationOfferHealthScreeningsAndWellnessCheckupsToEmployee: any | null;
  ergonomicWorkstationsProvidedToPromotePhysicalComfort: any | null;
  doesWorkplaceCafeteriaOfferHealthyFoodOptions: any | null;
  averageSickLeavesTakesByEmployeeEveryMonth: any | null;
  averageMonthlyEmployeeAbsentismDueToSickLeave: any | null;
  doesOrganizationOfferHealthInsuranceBenefits: any | null;
  employeesAvailedHealthInsuranceBenefits: any | null;
}

export interface OrganizationalMentalHealthAssessmentModel {
  areEmployeesEncouragedToTakeMentalHealthDays: any | null;
  doesOrganizationProvideResourcesForStressManagement: any | null;
  areManagersTrainedToRecognizeSignsOfEmotionalDistress: any | null;
  areMindfulnessAndMeditationSessionsOfferedToEmployees: any | null;
  doesOrganizationOfferProfessionalCounsellingForMentalHealthConcernsRelatedToWork: any | null;
}

export interface OrganizationDetailAssessmentModel {
  organizationName: any | null;
  organizationEmail: any | null;
  organizationAge: any | null;
  organizationEmployeeStrength: any | null;
  yourRole: any | null;
  yourCountry: any | null;
  yourCity: any | null;
  organizationOfficeAddress: any | null;
}

export interface OrganizationalSocialConnectionAndCommunityAssessmentModel {
  areThereEmployeeResourceGorups: any | null;
  doesOrganizationOrganizeTeamBuildingActivity: any | null;
  areThereOpportunitiesForEmployeesToEngageInVolunteering: any | null;
  rateLevelOfCollaborationCrossFunctionalEngagement: any | null;
  doEmployeesHaveOptionToWorkRemotely: any | null;
  howOftenWorkingOutsideOfRegularOfficeHours: any | null;
  howOftenWorkResponsibilitiesInterfereYourPersonalTime: any | null;
  areYouSatisfiedWithOrganizationPolicies: any | null;
  howWellOrganizationSupportEmployeesFamilyNeeds: any | null;
  isOpenCommunicationCultureEncouragesFeedback: any | null;
  opportunitiesForSkillDevelopmentProvided: any | null;
  areEmployeesRecognizedForContributions: any | null;
  describeLevelOfCollaborationAcrossDepartments: any | null;
  howComfortableAreYouDiscussingWorkRelatedConcernsWithImmediateSupervisor: any | null;
  anythingYouLikeToShareAboutOrganizationCulture: any | null;
}

export class OrganizationalAssessmentSearchReqModel {
  constructor() {
    this.searchColumns = [];
    this.isSubmittedFilter = null;
  }
  searchColumns: string[];
  isSubmittedFilter: boolean;
}
