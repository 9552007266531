import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AlphaValidator, FormErrorMessage, NumericValidator, focusInvalidInput } from 'app/shared/custom.validators';
import { NotificationService } from 'app/shared/services/notification.service';
import { DeviceType } from 'app/shared/shared.enum';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'auth-sign-up',
  templateUrl: './sign-up.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sign-up.component.scss'],
  animations: fuseAnimations,
})
export class AuthSignUpComponent implements OnInit, OnDestroy {
  unSubscribe$ = new Subject();
  form: FormGroup;
  isSubmitted: boolean = false;
  redirectURL: string = '';
  isExternalRedirect: boolean = false;
  signUpReCaptcha: string;
  stepId: number = 1;
  sendOtpTimer: number = 0;
  canSendOtp: boolean = true;
  isMobileBuild = environment.isMobileBuild;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private _notificationService: NotificationService,
    private _el: ElementRef,
  ) {
    this.form = new FormGroup({
      deviceType: new FormControl(DeviceType.Web, [Validators.required]),
      firstName: new FormControl(null, [Validators.required, Validators.maxLength(100), AlphaValidator]),
      middleName: new FormControl(null),
      lastName: new FormControl(null, [Validators.required, Validators.maxLength(100), AlphaValidator]),
      mobile: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(10), NumericValidator]),
      email: new FormControl(null, [Validators.required, Validators.maxLength(100), Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      preferredLanguage: new FormControl(1, [Validators.required]), //English
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unSubscribe$.next(null);
    this.unSubscribe$.complete();
  }

  // Form error messages
  getErrorMessage(control: string, formGroup?: FormGroup): string | null {
    if (this.isSubmitted) {
      this.form.markAllAsTouched();
    }
    const form = formGroup ?? this.form;
    if (this.isSubmitted || form?.get(control).touched) {
      return FormErrorMessage(form, control);
    }
    return null;
  }

  getControlFormArray(control: string, form: FormGroup): FormArray {
    return form.get(control) as FormArray;
  }

  getControlFormGroup(control: string, form: FormGroup): FormGroup {
    return form.get(control) as FormGroup;
  }

  validateForm(form: FormGroup): boolean {
    form.markAllAsTouched();
    this.isSubmitted = true;
    if (form.invalid) {
      focusInvalidInput(this._el);
    }
    return form.valid;
  }

  verifySignUpCaptcha(captchaRef: any): void {
    this.isSubmitted = true;
    if (this.form.valid) {
      if (!environment.production) {
        this.signUpCaptchaResolved(null);
      } else {
        if (this.signUpReCaptcha) {
          captchaRef.reset();
        }
        captchaRef.execute();
      }
    }
  }

  signUpCaptchaResolved(captchaResponse: string): void {
    this.signUpReCaptcha = captchaResponse;
    if (this.signUpReCaptcha || !environment.production) {
      this.signUp();
    }
  }

  signUp(): void {
    this.isSubmitted = true;

    if (!this.validateForm(this.form)) return;
    this.form.disable();

    this._authService
      .signUp(this.form.getRawValue())
      .pipe(
        finalize(() => {
          this.form.enable();
        }),
      )
      .subscribe((apiRes) => {
        if (apiRes.status) {
          this._notificationService.notify(apiRes.message);
          this._router.navigate(['/sign-in']);
        }
      });
  }
}
