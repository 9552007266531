import { ProgramCategoryResModel } from '../program-category/program-category.model';

export class ProgramSearchReqModel {
  constructor() {
    this.searchColumns = [];
    this.isActiveFilter = null;
    this.categoryFilter = null;
  }
  searchColumns: string[];
  isActiveFilter: string;
  categoryFilter: string;
}

export class ProgramResModel {
  createdBy: string;
  additionalInfo: string;
  benefits: string;
  price: number;
  categories: ProgramCategoryMappingResModel[];
  createdOn: string;
  description: string;
  id: number;
  imagePath: string;
  imageUrl: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedOn: string;
  title: string;
}

export class ProgramCategoryMappingResModel {
  programId: number;
  program: ProgramResModel;
  categoryId: number;
  category: ProgramCategoryResModel;
}
