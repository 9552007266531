import { HttpStatusCode } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FormErrorMessage, focusInvalidInput } from 'app/shared/custom.validators';
import { NotificationService } from 'app/shared/services/notification.service';
import { FieldErrorMsg } from 'app/shared/shared.const';
import { DeviceType, LoginType } from 'app/shared/shared.enum';
import { environment } from 'environments/environment';
import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'auth-sign-in',
  templateUrl: './sign-in.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sign-in.component.scss'],
  animations: fuseAnimations,
})
export class AuthSignInComponent implements OnInit, OnDestroy {
  unSubscribe$ = new Subject();
  loginForm: FormGroup;
  otpForm: FormGroup;
  isSubmitted: boolean = false;
  loginType = LoginType;
  redirectURL: string = '';
  isExternalRedirect: boolean = false;
  signInReCaptcha: string;
  stepId: number = 1;
  sendOtpTimer: number = 0;
  canSendOtp: boolean = true;
  loginWithOtp = new FormControl(false);
  isMobileBuild = environment.isMobileBuild;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private _notificationService: NotificationService,
    private _el: ElementRef,
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl(environment.isDevelopment ? 'rajatsehgal2006@gmail.com' : null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
      loginType: new FormControl(LoginType.Password, [Validators.required]),
      deviceType: new FormControl(DeviceType.Web, [Validators.required]),
    });

    this.otpForm = new FormGroup({
      otp: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.redirectURL = this._activatedRoute.snapshot.queryParamMap?.get('redirectURL') || 'dashboard';
    this.isExternalRedirect = (this._activatedRoute.snapshot.queryParamMap?.get('type') || '').toLowerCase() == 'external';

    this.loginWithOtp.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((loginWithOtp) => {
      this.loginForm.get('password').setValue(null);
      if (loginWithOtp) {
        this.loginForm.get('password').setValidators([Validators.nullValidator]);
        this.loginForm.get('password').disable();
        this.loginForm.get('loginType').setValue(LoginType.Otp);
      } else {
        this.loginForm.get('password').setValidators([Validators.required]);
        this.loginForm.get('password').enable();
        this.loginForm.get('loginType').setValue(LoginType.Password);
      }
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(null);
    this.unSubscribe$.complete();
  }

  // Form error messages
  getErrorMessage(control: string, formGroup?: FormGroup): string | null {
    if (this.isSubmitted) {
      this.loginForm.markAllAsTouched();
    }
    const form = formGroup ?? this.loginForm;
    if (this.isSubmitted || form?.get(control).touched) {
      return FormErrorMessage(form, control);
    }
    return null;
  }

  validateForm(form: FormGroup): boolean {
    form.markAllAsTouched();
    this.isSubmitted = true;
    if (form.invalid) {
      this._notificationService.notify(FieldErrorMsg);
      focusInvalidInput(this._el);
    }
    return form.valid;
  }

  verifySignInCaptcha(captchaRef: any): void {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      if (!environment.production) {
        this.signInCaptchaResolved(null);
      } else {
        if (this.signInReCaptcha) {
          captchaRef.reset();
        }
        captchaRef.execute();
      }
    }
  }

  signInCaptchaResolved(captchaResponse: string): void {
    this.signInReCaptcha = captchaResponse;
    if (this.signInReCaptcha || !environment.production) {
      this.signIn();
    }
  }

  getOtp(): void {
    this.isSubmitted = true;
    this.canSendOtp = false;
    this.sendOtpTimer = 30;
    this.loginForm.disable();

    const requestData = {
      username: this.loginForm.get('username')?.value,
    };
    this._authService
      .sendLoginOtp(requestData)
      .pipe(
        catchError((response) => {
          if (response.status === HttpStatusCode.NotFound) {
            this._notificationService.notify('Invalid username');
          }
          return throwError(() => null);
        }),
        finalize(() => {
          this.isSubmitted = false;
          this.loginForm.enable();
        }),
      )
      .subscribe((apiRes) => {
        this.stepId = 2;
        this._notificationService.notify(apiRes.message);

        const interval = setInterval(() => {
          this.sendOtpTimer--;
        }, 1000);

        setTimeout(() => {
          this.canSendOtp = true;
          clearInterval(interval);
        }, this.sendOtpTimer * 1000);
      });
  }

  signIn(): void {
    this.isSubmitted = true;
    let loginType = this.loginForm.value.loginType;
    if (this.stepId === 1) {
      if (!this.validateForm(this.loginForm)) return;

      if (loginType == LoginType.Otp || loginType == LoginType.TwoFactorAuthentication) {
        this.getOtp();
        return;
      }
    }

    if (this.stepId === 2 && (loginType == LoginType.Otp || loginType == LoginType.TwoFactorAuthentication)) {
      if (!this.validateForm(this.otpForm)) return;
    }

    this.loginForm.disable();
    this.otpForm.disable();

    const loginForm = {
      username: this.loginForm.get('username').value,
      password: this.loginForm.get('password').value,
      loginType: this.loginForm.get('loginType').value,
      deviceType: this.loginForm.get('deviceType').value,
    };

    if (this.otpForm.get('otp').value) {
      loginForm['otp'] = this.otpForm.get('otp').value;
    }

    this._authService
      .signIn(loginForm)
      .pipe(
        catchError((response) => {
          if (response?.status == HttpStatusCode.NotFound) {
            this._notificationService.notify('Invalid username');
          }
          return throwError(() => response);
        }),
        finalize(() => {
          this.loginForm.enable();
          this.otpForm.enable();
        }),
      )
      .subscribe((apiRes) => {
        if (this.redirectURL && !this.isExternalRedirect) this._router.navigateByUrl('/accounts?redirectURL=' + this.redirectURL);
        else this._router.navigateByUrl(this.redirectURL);
      });
  }
}
