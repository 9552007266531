import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { AuthService, IJwtTokenData } from 'app/services/auth.service';
import { Role } from 'app/shared/shared.enum';
import _ from 'lodash';
import { Observable, ReplaySubject, Subject, of, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  accessRole: Role;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _authService: AuthService) {
    this.update();
  }

  filterNavigations(navigationItems: FuseNavigationItem[], role: Role): FuseNavigationItem[] {
    navigationItems = _.filter(navigationItems, (item: FuseNavigationItem) => {
      return item.roles.includes(role);
    });
    navigationItems.forEach((navigationItem) => {
      navigationItem.children = this.filterNavigations(navigationItem.children, role);
    });

    return navigationItems;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    // return this._httpClient.get<Navigation>('api/common/navigation').pipe(
    //     tap((navigation) =>
    //     {
    //         this._navigation.next(navigation);
    //     }),
    // );
    const navigations: Navigation = {
      compact: [],
      default: [],
      futuristic: [],
      horizontal: [],
    };
    return of(navigations);
  }

  update(): void {
    this._authService.tokenData$.pipe(takeUntil(this._unsubscribeAll)).subscribe((tokenData: IJwtTokenData) => {
      this.accessRole = Role[tokenData.role];
      const filteredNavigationItems = this.filterNavigations(this.navigationItems, this.accessRole);
      const filteredNavigations: Navigation = {
        compact: filteredNavigationItems,
        default: filteredNavigationItems,
        futuristic: filteredNavigationItems,
        horizontal: filteredNavigationItems,
      };

      this._navigation.next(filteredNavigations);
    });
  }

  navigationItems: FuseNavigationItem[] = [
    {
      id: 'tenant',
      title: 'Aggregators',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/tenant',
      roles: [Role.SuperAdmin],
    },
    {
      id: 'master-data-management',
      title: 'Master Data Management',
      type: 'collapsable',
      icon: 'heroicons_outline:table-cells',
      roles: [Role.Admin],
      children: [
        {
          id: 'country',
          title: 'Country',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/master/country',
          roles: [Role.Admin],
        },
        {
          id: 'state',
          title: 'State',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/master/state',
          roles: [Role.Admin],
        },
        {
          id: 'city',
          title: 'City',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/master/city',
          roles: [Role.Admin],
        },
        {
          id: 'program-management',
          title: 'Programs',
          type: 'collapsable',
          icon: 'heroicons_outline:table-cells',
          roles: [Role.Admin],
          children: [
            {
              id: 'program-category',
              title: 'Program Category',
              type: 'basic',
              icon: 'heroicons_outline:cube',
              link: '/master/program-category',
              roles: [Role.Admin],
            },
            {
              id: 'program',
              title: 'Program',
              type: 'basic',
              icon: 'heroicons_outline:cube',
              link: '/master/program',
              roles: [Role.Admin],
            },
          ],
        },
        {
          id: 'group-program-management',
          title: 'Group Programs',
          type: 'collapsable',
          icon: 'heroicons_outline:table-cells',
          roles: [Role.Admin],
          children: [
            {
              id: 'group-program-category',
              title: 'Group Program Category',
              type: 'basic',
              icon: 'heroicons_outline:cube',
              link: '/master/group-program-category',
              roles: [Role.Admin],
            },
            {
              id: 'group-program',
              title: 'Group Program',
              type: 'basic',
              icon: 'heroicons_outline:cube',
              link: '/master/group-program',
              roles: [Role.Admin],
            },
          ],
        },
        {
          id: 'specialized-management',
          title: 'Specialized',
          type: 'collapsable',
          icon: 'heroicons_outline:table-cells',
          roles: [Role.Admin],
          children: [
            {
              id: 'specialized-category',
              title: 'Specialized Category',
              type: 'basic',
              icon: 'heroicons_outline:cube',
              link: '/master/specialized-category',
              roles: [Role.Admin],
            },
            {
              id: 'specialized-program',
              title: 'Specialized Program',
              type: 'basic',
              icon: 'heroicons_outline:cube',
              link: '/master/specialized-program',
              roles: [Role.Admin],
            },
          ],
        },
        {
          id: 'health-issue-management',
          title: 'Health Issues',
          type: 'collapsable',
          icon: 'heroicons_outline:table-cells',
          roles: [Role.Admin],
          children: [
            {
              id: 'health-issue-category',
              title: 'Health Issue Category',
              type: 'basic',
              icon: 'heroicons_outline:cube',
              link: '/master/health-issue-category',
              roles: [Role.Admin],
            },
            {
              id: 'health-issue',
              title: 'Health Issue',
              type: 'basic',
              icon: 'heroicons_outline:cube',
              link: '/master/health-issue',
              roles: [Role.Admin],
            },
          ],
        },
        {
          id: 'goal',
          title: 'Goal',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/master/goal',
          roles: [Role.Admin],
        },
        {
          id: 'service-category',
          title: 'Service Category',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/master/service-category',
          roles: [Role.Admin],
        },
        // {
        //   id: 'external-service-management',
        //   title: 'External Services',
        //   type: 'collapsable',
        //   icon: 'heroicons_outline:table-cells',
        //   roles: [Role.Admin],
        //   children: [
        //     {
        //       id: 'external-service-category',
        //       title: 'External Service Category',
        //       type: 'basic',
        //       icon: 'heroicons_outline:cube',
        //       link: '/master/external-service-category',
        //       roles: [Role.Admin],
        //     },
        //     {
        //       id: 'external-service',
        //       title: 'External Service',
        //       type: 'basic',
        //       icon: 'heroicons_outline:cube',
        //       link: '/master/external-service',
        //       roles: [Role.Admin],
        //     },
        //   ],
        // },
      ],
    },
    {
      id: 'corporates',
      title: 'Corporates',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/corporate',
      roles: [Role.Admin],
    },
    {
      id: 'packages',
      title: 'Packages',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/package',
      roles: [Role.Admin],
    },
    {
      id: 'customized-packages',
      title: 'Packages',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/customized-package',
      roles: [Role.Corporate],
    },
    {
      id: 'user-management',
      title: 'User Management',
      type: 'collapsable',
      icon: 'heroicons_outline:table-cells',
      roles: [Role.SuperAdmin, Role.Admin, Role.Corporate],
      children: [
        {
          id: 'super-admin',
          title: 'Super Admin',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/user-management/super-admin',
          roles: [Role.SuperAdmin],
        },
        {
          id: 'service-provider',
          title: 'Service Provider',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/user-management/service-provider',
          roles: [Role.Admin],
        },
        {
          id: 'employees',
          title: 'Employees',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/user-management/employee',
          roles: [Role.Corporate, Role.Admin],
        },
        {
          id: 'individual',
          title: 'Individual',
          type: 'basic',
          icon: 'heroicons_outline:cube',
          link: '/user-management/individual',
          roles: [Role.Admin],
        },
      ],
    },
    {
      id: 'calendar-preference',
      title: 'Calendar Preference',
      type: 'basic',
      icon: 'heroicons_outline:table-cells',
      link: '/calendar-preference',
      roles: [Role.ServiceProvider],
    },
    {
      id: 'unavailability',
      title: 'Unavailability',
      type: 'basic',
      icon: 'heroicons_outline:table-cells',
      link: '/unavailability',
      roles: [Role.ServiceProvider],
    },
    {
      id: 'transaction',
      title: 'Transactions',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/transaction',
      roles: [Role.Corporate],
    },
    {
      id: 'appointment',
      title: 'Appointments',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/appointment',
      roles: [Role.Admin, Role.ServiceProvider],
    },
    {
      id: 'group-appointment',
      title: 'Group Appointments',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/group-appointment',
      roles: [Role.Admin, Role.Corporate, Role.ServiceProvider],
    },

    // WebApp

    {
      id: 'webapp-home',
      title: 'Home',
      type: 'basic',
      icon: 'heroicons_outline:home',
      link: '/webapp',
      roles: [Role.Individual, Role.Employee],
    },
    {
      id: 'webapp-about',
      title: 'About',
      type: 'basic',
      icon: 'heroicons_outline:table-cells',
      link: '/webapp/about-us',
      roles: [Role.Individual, Role.Employee],
    },
    {
      id: 'webapp-faq',
      title: "FAQ's",
      type: 'basic',
      icon: 'heroicons_outline:table-cells',
      link: '/webapp/faq',
      roles: [Role.Individual, Role.Employee],
    },
    {
      id: 'webapp-policies',
      title: 'Policies',
      type: 'collapsable',
      icon: 'heroicons_outline:cube',
      roles: [Role.Individual, Role.Employee],
      children: [
        {
          id: 'webapp-cancellation-policy',
          title: 'Refund & Cancellation Policy',
          type: 'basic',
          icon: 'heroicons_outline:table-cells',
          link: '/webapp/cancellation-policy',
          roles: [Role.Individual, Role.Employee],
        },
        {
          id: 'webapp-privacy-policy',
          title: 'Privacy Policy',
          type: 'basic',
          icon: 'heroicons_outline:table-cells',
          link: '/webapp/privacy-policy',
          roles: [Role.Individual, Role.Employee],
        },
        {
          id: 'webapp-terms-of-use',
          title: 'Terms of use',
          type: 'basic',
          icon: 'heroicons_outline:table-cells',
          link: '/webapp/terms-of-use',
          roles: [Role.Individual, Role.Employee],
        },
      ],
    },
    {
      id: 'customized-packages',
      title: 'Packages',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/webapp/customized-package',
      roles: [Role.Individual, Role.Employee],
    },
    {
      id: 'sessions',
      title: 'Sessions',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/webapp/session',
      roles: [Role.Individual, Role.Employee],
    },
    {
      id: 'transactions',
      title: 'Transactions',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/webapp/transaction',
      roles: [Role.Individual, Role.Employee],
    },
    {
      id: 'appointment',
      title: 'Appointments',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/webapp/appointment',
      roles: [Role.Individual, Role.Employee],
    },
    {
      id: 'Corporate subscriptions',
      title: 'Corporate Subscriptions',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/corporate-subscription',
      roles: [Role.Admin, Role.Corporate],
    },
    {
      id: 'group-appointment',
      title: 'Group Appointments',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/group-appointment',
      roles: [Role.Employee],
    },
    {
      id: 'enquiry',
      title: 'Enquiries',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/enquiry',
      roles: [Role.Admin],
    },
    {
      id: 'organizational-assessment',
      title: 'Organizational Assessment',
      type: 'basic',
      icon: 'heroicons_outline:cube',
      link: '/organizational-assessment',
      roles: [Role.Admin],
    },
  ];
}
