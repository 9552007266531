import { Gender, Role } from 'app/shared/shared.enum';
import { CorporateResModel } from '../corporate/corporate.model';
import { GroupProgramResModel } from '../group-program/group-program.model';
import { ProgramResModel } from '../program/program.model';
import { SpecializedProgramResModel } from '../specialized-program/specialized-program.model';

export class UserManagementSearchReqModel {
  constructor() {
    this.searchColumns = [];
    this.isActiveFilter = null;
  }
  searchColumns: string[];
  isActiveFilter: string;
  isProfileUpdatedFilter: string;
  roleFilter: string;
}

export class UserManagementResModel {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  mobile: string;
  email: string;
  isActive: boolean;
  corporates: UserCorporateMappingModel[];
  createdBy: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: string;
}

export class UserCorporateMappingModel {
  id?: string;
  corporateId?: string;
  corporate?: CorporateResModel;
  userId?: string;
  basicProfile?: UserBasicProfileResModel;
  dietPlannerProfile?: UserDietPlannerProfileResModel;
  additionalProfile?: UserAdditionalProfileResModel;
  isActive?: boolean;
  role?: Role;
}

export class UserBasicProfileResModel {
  id: string;
  userGroupId: string;
  dob: string;
  gender: Gender;
  address: string;
  countryId: number;
  stateId: number;
  cityId: number;
  pincode: string;
  preferredLanguage: string;
  isActive: boolean;
  createdBy: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: string;
}

export class UserDietPlannerProfileResModel {
  id: string;
  userGroupId: string;
  activity: string;
  diseases: string;
  prefWorkoutTime: string;
  foodType: string;
  community: string;
  dietType: string;
  createdBy: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: string;
}

export class UserAdditionalProfileResModel {
  id: string;
  userGroupId: string;
  registrationNo: string;
  qualification: string;
  experience: number;
  ownClinicalPractice: boolean;
  clinicName: string;
  clinicAddress: string;
  clinicCountryId: number;
  clinicStateId: number;
  clinicCityId: number;
  clinicPincode: string;
  natureOfWork: string;
  programs: UserProgramMappingModel[];
  specializedPrograms: UserSpecializedProgramMappingModel[];
  groupPrograms: UserGroupProgramMappingModel[];
  createdBy: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: string;
}

export class UserProgramMappingModel extends ProgramResModel {
  constructor() {
    super();
    this.isSelected = false;
  }
  isSelected: boolean;
  mappingId: number;
  programId: number;
}

export class UserSpecializedProgramMappingModel extends SpecializedProgramResModel {
  constructor() {
    super();
    this.isSelected = false;
  }
  isSelected: boolean;
  mappingId: number;
  programId: number;
}

export class UserGroupProgramMappingModel extends GroupProgramResModel {
  constructor() {
    super();
    this.isSelected = false;
  }
  isSelected: boolean;
  mappingId: number;
  programId: number;
}
