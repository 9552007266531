import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { Observable, of } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard {
  constructor(private _authService: AuthService, private _storageService: StorageService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = route.queryParams.redirectURL || '';
    return this._check(redirectUrl);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = childRoute.queryParams.redirectURL || '';
    return this._check(redirectUrl);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  private _check(redirectURL: string): Observable<boolean> {
    // const token = this._authService.accessToken$;
    // const tokenData = this._authService.tokenData$;
    // if (!isNullEmpty(token)) {
    //   tokenData.subscribe((tokenData) => {
    //     if (tokenData.role) {
    //       redirectURL = isNullEmpty(redirectURL) ? '/dashboard' : redirectURL;
    //       this._router.navigate([redirectURL]);
    //       return of(false);
    //     }
    //     return of(true);
    //   });
    // }

    this._storageService.clearLocalStorage();
    this._storageService.clearSessionStorage();
    this._storageService.clearCookies();

    return of(true);
  }
}
