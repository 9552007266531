import { ElementRef } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function focusInvalidInput(_el: ElementRef): void {
  const invalidControl = _el.nativeElement.querySelector('[formcontrolname].ng-invalid') as HTMLInputElement;
  if (invalidControl) {
    invalidControl.focus();
  }
}

export function UrlValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    if (!control.value.startsWith('http') || !control.value.startsWith('https')) {
      return { urlValidator: true };
    } else {
      return null;
    }
  }
  return null;
}
export function MobileValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    if (!control.value.toString().match(/^[6-9][0-9]{9}$/)) {
      return { mobileValidator: true };
    } else {
      return null;
    }
  }
  return null;
}
export function EmailValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    if (!control.value.toString().match(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,8}$/)) {
      return { emailValidator: true };
    } else {
      return null;
    }
  }
  return null;
}
export function AlphaValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    if (!control.value.toString().match(/^[-a-zA-Z]+(( ?)+[-a-zA-Z]+)*$/)) {
      return { alphaValidator: true };
    } else {
      return null;
    }
  }
  return null;
}
export function NumericValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    if (!control.value.toString().match(/^[0-9]+$/)) {
      return { numericValidator: true };
    } else {
      return null;
    }
  }
  return null;
}
export function FloatNumericValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    if (!control.value.toString().match(/^[0-9.]+$/)) {
      return { numericValidator: true };
    } else {
      return null;
    }
  }
  return null;
}
export function AlphaNumericValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    if (!control.value.toString().match(/^[-a-zA-Z0-9]+(( ?)+[-a-zA-Z0-9]+)*$/)) {
      return { alphaNumericValidator: true };
    } else {
      return null;
    }
  }
  return null;
}
export function AllowedCharacterValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    if (!control.value.toString().match(/^[a-zA-Z0-9_&/,.\[\]]+(( ?)+[a-zA-Z0-9_&/,-.\[\]]+)*$/)) {
      return { allowedCharacterValidator: true };
    } else {
      return null;
    }
  }
  return null;
}
export function PasswordValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    const password = control.value.toString();
    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(password);
    const upperLetters = /[A-Z]+/.test(password);
    const numbers = /[0-9]+/.test(password);
    const symbols = regex.test(password);

    if (password.length < 6) {
      return { passwordValidator: true, minLength: true };
    } else if (password.length > 32) {
      return { passwordValidator: true, maxLength: true };
    } else if (!lowerLetters) {
      return { passwordValidator: true, lowerCase: true };
    } else if (!upperLetters) {
      return { passwordValidator: true, upperCase: true };
    } else if (!numbers) {
      return { passwordValidator: true, numbers: true };
    } else if (!symbols) {
      return { passwordValidator: true, symbols: true };
    } else {
      return null;
    }
  }
  return null;
}
export function PasswordValidation(password: string): boolean {
  const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
  const lowerLetters = /[a-z]+/.test(password);
  const upperLetters = /[A-Z]+/.test(password);
  const numbers = /[0-9]+/.test(password);
  const symbols = regex.test(password);

  if (password.length >= 6 && password.length < 32 && lowerLetters && upperLetters && numbers && symbols) {
    return true;
  }
  return false;
}
export function ConditionalValidator(predicate: any, validator: ValidatorFn, errorNamespace?: string): ValidatorFn {
  return (formControl: any) => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError: any = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  };
}
export function MatchControls(
  controlName: string,
  controlNameMessage: string,
  matchingControlName: string,
  matchingControlNameMessage: string,
): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.notMatch) {
      return null;
    }
    if (!control.value) {
      return null;
    }
    if (control.value.toString() !== matchingControl.value.toString()) {
      matchingControl.setErrors({
        notMatch: true,
        controlNameMessage,
        matchingControlNameMessage,
      });
    } else {
      matchingControl.setErrors(null);
    }

    return matchingControl;
  };
}

export function FormErrorMessage(formGroup: FormGroup, control: string): string | null {
  const controlValue = formGroup.get(control)?.errors;
  if (controlValue) {
    if (controlValue.required) {
      return 'This field is required';
    } else if (controlValue.minlength) {
      return 'Please enter at least ' + controlValue.minlength.requiredLength + ' characters';
    } else if (controlValue.maxlength) {
      return 'Please enter not more than  ' + controlValue.maxlength.requiredLength + ' characters';
    } else if (controlValue.min) {
      return 'Value should be minimum ' + controlValue.min.min;
    } else if (controlValue.max) {
      return 'Value should be less than ' + controlValue.max.max;
    } else if (controlValue.email) {
      return 'Please enter valid email';
    } else if (controlValue.urlValidator) {
      return 'Please enter valid url';
    } else if (controlValue.mobileValidator) {
      return 'Please enter valid mobile number';
    } else if (controlValue.alphaValidator) {
      return 'Please enter only alphabets';
    } else if (controlValue.numericValidator) {
      return 'Please enter only number';
    } else if (controlValue.alphaNumericValidator) {
      return 'Please enter only alphabets and numbers';
    } else if (controlValue.allowedCharacterValidator) {
      return `Please use only 'a-zA-Z0-9_&,./' special characters`;
    } else if (controlValue.passwordValidator) {
      return 'Password must be between 6 - 32 character, Contains 1 Uppercase 1 Lowercase 1 Number & 1 Special character';
    } else if (controlValue.notMatch) {
      return `${controlValue.controlNameMessage} and ${controlValue.matchingControlNameMessage} doesn't match`;
    } else if (controlValue.mustMatch) {
      return `${controlValue.controlNameMessage} and ${controlValue.matchingControlNameMessage} doesn't match`;
    } else {
      return controlValue.error;
    }
  }
  return null;
}
