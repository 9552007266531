<form [formGroup]="form" class="mt-2">
  <mat-horizontal-stepper [linear]="true" #hstepper>
    <mat-step *ngFor="let sec of questionsArr; let i = index" [stepControl]="getSubFormGroup(sec.formGroupName)">
      <ng-template matStepLabel> {{ sec.sectionHeading }}</ng-template>

      <p class="text-3xl text-center my-5">{{ sec.section }}</p>
      <form [formGroupName]="sec.formGroupName" class="mt-2">
        <div *ngFor="let ques of filterQuestions(sec); let j = index">
          <div class="mt-3">
            <ng-container *ngIf="ques.type == 'text' || ques.type == 'email' || ques.type == 'number'">
              <mat-form-field class="w-full">
                <mat-label>Q{{ j + 1 }}) {{ ques.question }}</mat-label>
                <input matInput [type]="ques.type" [min]="ques.min" [max]="ques.max" [formControlName]="ques.formControlName" required />
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="ques.type == 'radio'">
              <mat-label class="font-medium">Q{{ j + 1 }}) {{ ques.question }}</mat-label>
              <br />
              <mat-radio-group color="primary" [formControlName]="ques.formControlName">
                <ng-container *ngFor="let option of ques.options">
                  <mat-radio-button [value]="option.value">
                    {{ option.label == null ? option.value : option.label }}
                  </mat-radio-button>
                  <br />
                </ng-container>
              </mat-radio-group>
            </ng-container>
            <ng-container *ngIf="ques.type == 'select'">
              <mat-form-field class="w-full">
                <mat-label>Q{{ j + 1 }}) {{ ques.question }}</mat-label>
                <mat-select color="primary" [formControlName]="ques.formControlName">
                  <ng-container *ngFor="let option of ques.options">
                    <mat-option [value]="option.value">
                      {{ option.label == null ? option.value : option.label }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </form>
      <div class="mt-5">
        <button mat-raised-button color="primary" matStepperPrevious *ngIf="i != 0" class="mr-2 mt-4">Previous</button>
        <ng-container *ngIf="i + 1 != questionsArr.length">
          <button
            mat-raised-button
            color="primary"
            matStepperNext
            *ngIf="!isReadOnly"
            (click)="partialSave(sec.formGroupName)"
            class="mt-4"
          >
            Save & Next
          </button>
          <button mat-raised-button color="primary" matStepperNext *ngIf="isReadOnly" class="mt-4">Next</button>
        </ng-container>
      </div>

      <div class="mt-2 text-danger" *ngIf="!isReadOnly && getSubFormGroup(sec.formGroupName, form).invalid">
        You have missed
        <span class="h4">
          {{ ('0' + controlsRecursive(getSubFormGroup(sec.formGroupName, form))?.invalidControls?.length).slice(-2) }}
        </span>
        questions. <br />
        Please answer all the mentioned questions to move to next section
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <div class="ml-4 mt-5" *ngIf="!isReadOnly">
    <button mat-raised-button color="primary" (click)="form.valid ? submit() : ''" class="mt-4" [disabled]="!form.valid">
      Submit Assessment
    </button>
    <br />

    <small *ngIf="!form.valid"> Please answer all questions to submit </small>
  </div>
</form>
