import { UserManagementResModel } from 'app/dtos/user-management/user-management.model';

export class User extends UserManagementResModel {
  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }
  avatar?: string;
}

export interface ICorporate {
  id: string;
  corporateId: string;
  isActive: boolean;
  role: number;
  userId: string;
  corporate: any;
}
