import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private _encryption: EncryptionService, private _cookieService: CookieService) {}
  // Local storage
  setValueToLocalStorage(key: string, value: string): void {
    if (value) {
      window.localStorage.setItem(key, this._encryption.encryptData(value));
    }
  }

  getValueFromLocalStorage(key: string): string | null {
    if (window.localStorage.getItem(key)) {
      return this._encryption.decryptData(window.localStorage.getItem(key));
    }
    return null;
  }

  clearLocalStorage(): void {
    window.localStorage.clear();
  }

  removeLocalStorageKey(key: string): void {
    window.localStorage.removeItem(key);
  }

  // Session storage
  setValueToSessionStorage(key: string, value: string): void {
    if (value) {
      window.sessionStorage.setItem(key, this._encryption.encryptData(value));
    }
  }

  getValueFromSessionStorage(key: string): string | null {
    if (window.sessionStorage.getItem(key)) {
      return this._encryption.decryptData(window.sessionStorage.getItem(key));
    }
    return null;
  }

  clearSessionStorage(): void {
    window.sessionStorage.clear();
  }

  removeSessionStorageKey(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  // Cookies
  setValueToCookies(key: string, value: string): void {
    if (value) {
      this._cookieService.set(key, this._encryption.encryptData(value), null, '/');
    }
  }

  getValueFromCookies(key: string): string | null {
    if (this._cookieService.get(key)) {
      return this._encryption.decryptData(this._cookieService.get(key));
    }
    return null;
  }

  clearCookies(): void {
    this._cookieService.deleteAll();
  }

  removeCookiesKey(key: string): void {
    this._cookieService.delete(key);
  }
}
