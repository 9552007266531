import { CommonModule, DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RecaptchaGlobalSettings } from 'app/modules/auth/auth.module';
import { OrganizationalAssessmentCommonComponent } from 'app/modules/component/organizational-assessment/assessment.component';
import { CountryService } from 'app/services/country.service';
import { OrganizationalAssessmentService } from 'app/services/organizational-assessment.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { environment } from 'environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaModule } from 'ng-recaptcha';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-open-organizational-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
    MatTableModule,
    OrganizationalAssessmentCommonComponent,
    RecaptchaModule,
  ],
  providers: [
    CountryService,
    AuthService,
    OrganizationalAssessmentService,
    OrganizationalAssessmentCommonComponent,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: RecaptchaGlobalSettings,
    },
  ],
})
export class AppOpenOrganizationalAssessmentComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isReadOnly: boolean = false;

  form = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    otp: new FormControl(null, []),
  });
  part = 1;
  isEmailOtpSent = false;
  isSubmitted = false;
  totalQuestions = 0;
  completionPercentage = 0;
  questions = [];
  sendOtpReCaptcha: string;
  verifyOtpReCaptcha: string;
  isMobileBuild = environment.isMobileBuild;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _renderer: Renderer2,
    private _authService: AuthService,
    private _notificationService: NotificationService,
    private _organizationalAssessmentCommonComponent: OrganizationalAssessmentCommonComponent,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this._renderer.addClass(this._document.body, 'gradient');
    // document.getElementsByTagName('body')[0].style.minWidth = '1200px';

    this.questions = this._organizationalAssessmentCommonComponent.questionsArr;
    this.totalQuestions = this._organizationalAssessmentCommonComponent.totalQuestionsCount;
  }

  ngOnDestroy(): void {
    this._renderer.removeClass(this._document.body, 'gradient');
    // document.getElementsByTagName('body')[0].style.removeProperty('min-width');
  }

  ngAfterViewInit() {
    this._changeDetectorRef.detectChanges();
  }

  verifySendOtpCaptcha(captchaRef: any): void {
    this.isSubmitted = true;
    if (this.form.valid) {
      if (!environment.production) {
        this.sendOtpCaptchaResolved(null);
      } else {
        if (this.sendOtpReCaptcha) {
          captchaRef.reset();
        }
        captchaRef.execute();
      }
    }
  }

  sendOtpCaptchaResolved(captchaResponse: string): void {
    this.sendOtpReCaptcha = captchaResponse;
    if (this.sendOtpReCaptcha || !environment.production) {
      this.sendOtp();
    }
  }

  verifyVerifyOtpCaptcha(captchaRef: any): void {
    this.isSubmitted = true;
    if (this.form.valid) {
      if (!environment.production) {
        this.verifyOtpCaptchaResolved(null);
      } else {
        if (this.verifyOtpReCaptcha) {
          captchaRef.reset();
        }
        captchaRef.execute();
      }
    }
  }

  verifyOtpCaptchaResolved(captchaResponse: string): void {
    this.verifyOtpReCaptcha = captchaResponse;
    if (this.verifyOtpReCaptcha || !environment.production) {
      this.verifyOtp();
    }
  }

  getSubFormGroup(formGroupName: string, form: FormGroup = this.form): FormGroup {
    return form.get(formGroupName) as FormGroup;
  }

  sendOtp(): void {
    if (this.form.get('email').valid || this.form.get('email').disabled) {
      this._authService.sendOrganizationalAssessmentOtp(this.form.getRawValue()).subscribe((apiRes) => {
        this._notificationService.notify(apiRes.message);
        this.form.get('email').disable();
        this.form.get('otp').setValidators(Validators.required);
        this.isEmailOtpSent = true;
      });
    }
  }

  verifyOtp(): void {
    if (!this.form.valid) return;

    this._authService.generateOrganizationalAssessmentToken(this.form.getRawValue()).subscribe((apiRes) => {
      setTimeout(() => {
        this.part = 3;
      }, 500);
    });
  }

  formSubmitted(event: boolean): void {
    if (event) {
      this.isSubmitted = true;
      this.part = 4;
    }
  }
}
