import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseCardComponent } from '@fuse/components/card';
import { authRoutes } from 'app/modules/auth/auth.routing';
import { NgOtpInputModule } from 'ng-otp-input';
import { AuthSignInComponent } from './sign-in/sign-in.component';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { AuthSignUpComponent } from './sign-up/sign-up.component';

export const RecaptchaGlobalSettings: RecaptchaSettings = {
  siteKey: environment.recaptchaKey,
};
@NgModule({
  declarations: [AuthSignInComponent, AuthSignUpComponent],
  imports: [
    RouterModule.forChild(authRoutes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FuseCardComponent,
    FuseAlertComponent,
    NgOtpInputModule,
    RecaptchaModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: RecaptchaGlobalSettings,
    },
  ],
})
export class AuthModule {}
