import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from './app.resolvers';
import { AppOpenOrganizationalAssessmentComponent } from './modules/open/organizational-assessment/assessment.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { NoAuthGuard } from './shared/guard/noAuth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: '',
        loadChildren: () => import('app/modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'open',
        children: [
          {
            path: 'organizational-assessment',
            component: AppOpenOrganizationalAssessmentComponent,
          },
        ],
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'accounts',
        loadChildren: () => import('app/modules/common/choose-account/choose-account.module').then((m) => m.ChooseAccountModule),
      },
      {
        path: 'join-session',
        loadChildren: () => import('app/modules/common/join-session/join-session.module').then((m) => m.JoinSessionModule),
      },
    ],
  },

  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // {
      //   path: '',
      //   redirectTo: 'dashboard',
      //   pathMatch: 'full',
      // },
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/common/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('app/modules/common/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'master',
        loadChildren: () => import('app/modules/masters/masters.module').then((m) => m.MasterModule),
      },
      {
        path: 'user-management',
        loadChildren: () => import('app/modules/user-management/user-management.module').then((m) => m.UserManagementModule),
      },
      {
        path: 'calendar-preference',
        loadChildren: () => import('app/modules/calendar-preference/calendar-preference.module').then((m) => m.CalendarPreferenceModule),
      },
      {
        path: 'unavailability',
        loadChildren: () => import('app/modules/unavailability/unavailability.module').then((m) => m.UnavailabilityModule),
      },
      {
        path: 'tenant',
        loadChildren: () => import('app/modules/tenant/tenant.module').then((m) => m.TenantModule),
      },
      {
        path: 'corporate',
        loadChildren: () => import('app/modules/corporate/corporate.module').then((m) => m.CorporateModule),
      },
      {
        path: 'corporate-subscription',
        loadChildren: () =>
          import('app/modules/corporate-subscription/corporate-subscription.module').then((m) => m.CorporateSubscriptionModule),
      },
      {
        path: 'package',
        loadChildren: () => import('app/modules/package/package.module').then((m) => m.PackageModule),
      },
      {
        path: 'customized-package',
        loadChildren: () => import('app/modules/customized-package/customized-package.module').then((m) => m.CustomizedPackageModule),
      },
      {
        path: 'transaction',
        loadChildren: () => import('app/modules/transaction/transaction.module').then((m) => m.TransactionModule),
      },
      {
        path: 'appointment',
        loadChildren: () => import('app/modules/appointment/appointment.module').then((m) => m.AppointmentModule),
      },
      {
        path: 'group-appointment',
        loadChildren: () => import('app/modules/group-appointment/group-appointment.module').then((m) => m.GroupAppointmentModule),
      },
      {
        path: 'assessment',
        loadChildren: () => import('app/modules/assessment/assessment.module').then((m) => m.AssessmentModule),
      },
      {
        path: 'organizational-assessment',
        loadChildren: () =>
          import('app/modules/organizational-assessment/organizational-assessment.module').then((m) => m.OrganizationalAssessmentModule),
      },

      {
        path: 'enquiry',
        loadChildren: () => import('app/modules/enquiry/enquiry.module').then((m) => m.EnquiryModule),
      },
      {
        path: 'webapp',
        loadChildren: () => import('app/modules/webapp/webapp.module').then((m) => m.WebAppModule),
      },
    ],
  },

  { path: '**', pathMatch: 'full', redirectTo: 'sign-in' },
];
