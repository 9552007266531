import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from './shared/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, CommonModule, MatProgressBarModule, MatSnackBarModule],
  providers: [],
})
export class AppComponent implements OnDestroy {
  unSubscribe$ = new Subject();

  constructor(private _notificationService: NotificationService, private _snacbar: MatSnackBar) {
    this._notificationService._message$.pipe(takeUntil(this.unSubscribe$)).subscribe((message) => {
      this._snacbar.open(message, 'Close', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: 'snackbar',
      });
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(null);
    this.unSubscribe$.complete();
  }
}
