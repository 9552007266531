export const environment = {
  production: false,
  isDevelopment: false,
  isMobileBuild: false,
  storage: {
    loginDataKey: 'WellbeUSRLOGDAT',
    loginTokenKey: 'WellbeUSRLOGTOKENDAT',
    registerationDetailsKey: 'WellbeUSRREGDAT',
    resetPasswordKey: 'WellbeUSRRPDAT',
    organizationalAssessmentTokenKey: 'WellbeOATOKEN',
  },
  apiUrl: 'https://stg-heal-api.wellbe.fit',
  zoomConfig: {
    sdkKey: 'K8IdtD1wQ42hFWcre0Wrkw',
    sdkSecret: 'vsrGkqB3kQUPh9ox6Z9xZZTRRTfr7sFP',
    leaveUrl: 'https://stg-heal.wellbe.fit',
  },
  recaptchaKey: '6LcEPFgoAAAAACkBPunOxOY6F9of2wH1T7yV544E',
};
