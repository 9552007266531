<div
  class="absolute inset-0 flex flex-col sm:flex-row flex-auto min-w-0 overflow-hidden items-center md:items-start sm:justify-center md:justify-start"
>
  <div class="relative hidden md:flex flex-col items-center justify-center w-1/2 h-full lg:px-28 overflow-hidden bg-white">
    <div class="self-start mb-8 w-40">
      <img src="assets/images/logo/logo.png" alt="" />
    </div>
    <img src="assets/images/pages/auth/login-image.png" alt="" />
  </div>
  <div
    class="flex overflow-y-auto items-center justify-center w-full sm:w-auto h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card bg-theme"
  >
    <div class="w-full max-w-100 sm:w-100 mx-auto sm:mx-0">
      <div class="text-center text-white">
        <!-- Logo -->
        <div class="mb-10 w-40 m-auto md:hidden">
          <img src="assets/images/logo/logo-white.png" alt="" />
        </div>

        <!-- Title -->
        <div class="text-3xl font-extrabold tracking-tight leading-tight uppercase mt-8">
          Wellbe
          <mat-icon [svgIcon]="'heroicons_solid:lock-closed'" class="text-white"></mat-icon>
        </div>
        <div class="mt-0.5 text-2xl">
          <div>Sign up to start the journey</div>
        </div>
      </div>
      <div class="mt-8">
        <!-- Signup Form -->
        <form [formGroup]="form" class="flex flex-col" autocomplete="off">
          <div class="flex flex-col sm:flex-row sm:gap-3">
            <div class="flex-1">
              <mat-form-field floatLabel="always" class="w-full">
                <mat-label class="font-bold text-white">First Name </mat-label> <br />
                <input matInput placeholder="First Name" formControlName="firstName" />
                <mat-error class="text-white">
                  {{ getErrorMessage('firstName') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="flex-1">
              <mat-form-field floatLabel="always" class="w-full">
                <mat-label class="font-bold text-white">Last Name </mat-label> <br />
                <input matInput placeholder="Last Name" formControlName="lastName" />
                <mat-error class="text-white">
                  {{ getErrorMessage('lastName') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-3">
            <div class="flex-1">
              <mat-form-field floatLabel="always" class="w-full">
                <mat-label class="font-bold text-white">Email </mat-label> <br />
                <input matInput placeholder="user@example.com" formControlName="email" />
                <mat-error class="text-white">
                  {{ getErrorMessage('email') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-3">
            <div class="flex-1">
              <mat-form-field floatLabel="always" class="w-full">
                <mat-label class="font-bold text-white">Mobile </mat-label> <br />
                <input matInput placeholder="810*******" formControlName="mobile" />
                <mat-error class="text-white">
                  {{ getErrorMessage('mobile') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-3">
            <div class="flex-1">
              <mat-form-field floatLabel="always" class="w-full">
                <mat-label class="font-bold text-white">Password </mat-label> <br />
                <input matInput type="password" placeholder="******" formControlName="password" />
                <mat-error class="text-white">
                  {{ getErrorMessage('password') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="text-center">
            <re-captcha #signUpCaptchaRef="reCaptcha" (resolved)="signUpCaptchaResolved($event)" size="invisible" [hidden]="isMobileBuild">
            </re-captcha>

            <button
              class="fuse-mat-button-large mt-10 bg-white text-theme font-bold px-15 text-xl"
              mat-flat-button
              [color]="'primary'"
              [disabled]="form.disabled"
              (click)="isMobileBuild ? signUp() : verifySignUpCaptcha(signUpCaptchaRef)"
            >
              <span *ngIf="!form.disabled"> Signup </span>
              <mat-progress-spinner *ngIf="form.disabled" [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
            </button>
          </div>

          <div class="text-white text-center mt-3 font-medium">
            Already registered?
            <a class="font-extrabold" routerLink="/sign-in">Sign in</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
