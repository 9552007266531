import { GroupProgramCategoryResModel } from '../group-program-category/group-program-category.model';

export class GroupProgramSearchReqModel {
  constructor() {
    this.searchColumns = [];
    this.isActiveFilter = null;
    this.categoryFilter = null;
  }
  searchColumns: string[];
  isActiveFilter: string;
  categoryFilter: string;
}

export class GroupProgramResModel {
  createdBy: string;
  additionalInfo: string;
  benefits: string;
  price: number;
  categories: GroupProgramCategoryMappingResModel[];
  createdOn: string;
  description: string;
  id: number;
  imagePath: string;
  imageUrl: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedOn: string;
  title: string;
}

export class GroupProgramCategoryMappingResModel {
  programId: number;
  program: GroupProgramResModel;
  categoryId: number;
  category: GroupProgramCategoryResModel;
}
