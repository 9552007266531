import { provideHttpClient, withInterceptors, withInterceptorsFromDi, withRequestsMadeViaParent } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { provideFuse } from '@fuse';
import { FuseConfig } from '@fuse/services/config';
import { appRoutes } from 'app/app.routes';
import { provideIcons } from 'app/core/icons/icons.provider';
import { CookieService } from 'ngx-cookie-service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { AuthorizationInterceptor } from './shared/services/interceptor.service';

export const fuseConfig: FuseConfig = {
  layout: 'classic',
  scheme: 'light',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px',
  },
  theme: 'theme-teal',
  themes: [
    {
      id: 'theme-default',
      name: 'Default',
    },
    {
      id: 'theme-brand',
      name: 'Brand',
    },
    {
      id: 'theme-teal',
      name: 'Teal',
    },
    {
      id: 'theme-rose',
      name: 'Rose',
    },
    {
      id: 'theme-purple',
      name: 'Purple',
    },
    {
      id: 'theme-amber',
      name: 'Amber',
    },
  ],
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withRequestsMadeViaParent(), withInterceptorsFromDi(), withInterceptors([AuthorizationInterceptor])),
    provideRouter(appRoutes, withPreloading(PreloadAllModules), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),

    // Material Date Adapter
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'dd/MM/yyyy',
        },
        display: {
          dateInput: 'dd/MM/yyyy',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'dd/MM/yyyy',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },

    // Fuse
    provideIcons(),
    provideFuse({ fuse: fuseConfig }),

    { provide: ErrorHandler, useClass: ErrorHandlerService },
    CookieService,
  ],
};
